.Navbar{

    background-color: green;
    font-family: Arial, Helvetica, sans-serif;
    
}

.links{
    text-decoration: none;
    font-size: 20px;
    /* margin-left: 15px; */
    font-family: Arial, Helvetica, sans-serif;
    color: white !important;
}

.Navbar.leftside{
    flex: 50%;
    background-color: red;

}

.Navbar.rightSide{

    flex: 50%;
    background-color: aqua;

}

.newclassfortest{
    background-color: burlywood;
    color: black !important;
    text-decoration-color: black;
    
}

.headerColorForTable{
    background-color: blue;
}

.OrderTableText1 {
    color : 'white';
  }
  
 .OrderTableTextNew2 {
    color : 'white';
    background-color:'yellow' !important;
  }

