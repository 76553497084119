.main {
    text-align: center;
    justify-content: center;
    display: flex;
    padding-top: 90px;
    padding-bottom: 90px;
    background-color: #e1e3eb;
}

.sub-main {
    display: flex;
    justify-content: center;
    height: 500px;
    width: 500px;
    box-shadow: 11px 12px 13px 12px rgb(207, 207, 207);
    padding-top: 30px;
    border-radius: 60px;
    background-color: white;
}

.imgs {
    padding-top: 20px;
    justify-content: center;
    display: flex;
}

.logout-button{
    font-size :20px;
    width: auto;

}

.container-image {
    background-color: rgb(223, 221, 221);
    border-radius: 150px;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 115px;
    width: 115px;
}

.profile {
    height: 100px;
    width: 100px;
    border-radius: 130px;
}

input {
    width: 300px;
    height: 50px;
    border-radius: 60px;
    box-shadow: inset 0px 0px 25px 0px #888;
    border: none;
    outline: none;
    background-color: #fff;
}

.email {
    height: 45px;
    width: 45px;
    position: absolute;
    padding: 14px 0 0 25px;
}

.name {
    padding-left: 70px;
    font-size: 20px;
}

.second-input {
    padding-top: 20px;
}

button {
    width: 380px;
    height: 50px;
    border-radius: 60px;
    background-color: green;
    color: white;
    font-size: 25px;
    border: none;
}

.login-button {
    padding-top: 25px;
}

.link {
    font-size: 25px;
    font-weight: 400;
}

a {
    color: blue;
}